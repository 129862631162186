import http from "../utils/http"

//交易数据
export function MemberStatList(params) {
    return http({
        url: "/api/Devstatistics/getMemberStatList",
        data: params
    })
}
//财务数据
export function StatTotal(params) {
    return http({
        url: "/api/Devstatistics/getStatTotal",
        data: params
    })
}
//工作台
export function orderTodo(params) {
    return http({
        url: "/api/bulkorder/orderTodo",
        data: params
    })
}
//会员概况
export function memberOverview(params) {
    return http({
        url: "/api/Childmember/memberOverview",
        data: params
    })
}